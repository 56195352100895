// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IqloxVszBkhLjXH0z6Yw{font-size:18px;font-weight:600;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.tPSQTbmcfNIQR4SUTOmh{padding:24px 24px 10px 24px;position:relative}.dGzjK1PNbp05STmIP_Ci{position:absolute;top:5px;left:5px;height:17px;width:17px}.fRb9TwVsMy8r2RVlhCSg{font-size:12px;font-weight:bold}`, "",{"version":3,"sources":["webpack://./src/pages/ListMultipleWorks/component/AddWorksSection/components/WorksList/components/WorkCard/component/Header/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CACF,sBACE,2BAAA,CACA,iBAAA,CACF,sBACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,WAAA,CACA,UAAA,CACF,sBACE,cAAA,CACA,gBAAA","sourcesContent":[".title\n  font-size: 18px\n  font-weight: 600\n  overflow: hidden\n  white-space: nowrap\n  text-overflow: ellipsis\n.header\n  padding: 24px 24px 10px 24px\n  position: relative\n.checkbox\n  position: absolute\n  top: 5px\n  left: 5px\n  height: 17px\n  width: 17px\n.publisher\n  font-size: 12px\n  font-weight: bold"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `IqloxVszBkhLjXH0z6Yw`,
	"header": `tPSQTbmcfNIQR4SUTOmh`,
	"checkbox": `dGzjK1PNbp05STmIP_Ci`,
	"publisher": `fRb9TwVsMy8r2RVlhCSg`
};
export default ___CSS_LOADER_EXPORT___;
