// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kCrxSrz4G0ZtsZZCRTyf{display:flex;border:1px solid #000;border-radius:10px;padding:10px 15px;font-size:14px;gap:10px;height:250px}`, "",{"version":3,"sources":["webpack://./src/pages/ListMultipleWorks/component/FormSection/components/StandardLicense/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,iBAAA,CACA,cAAA,CACA,QAAA,CACA,YAAA","sourcesContent":[".standardLicense\n  display: flex\n  border: 1px solid #000\n  border-radius: 10px\n  padding: 10px 15px\n  font-size: 14px\n  gap: 10px\n  height: 250px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"standardLicense": `kCrxSrz4G0ZtsZZCRTyf`
};
export default ___CSS_LOADER_EXPORT___;
