// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MvPa32ToA6OS8GHeBm5Y{display:inline-flex}@media(max-width: 768px){.MvPa32ToA6OS8GHeBm5Y{width:100%}.MvPa32ToA6OS8GHeBm5Y svg{width:21px;height:21px}}.MvPa32ToA6OS8GHeBm5Y>*{border-right:none !important;border-radius:0 !important}.MvPa32ToA6OS8GHeBm5Y>*:first-child{border-radius:5px 0 0 5px !important}.MvPa32ToA6OS8GHeBm5Y>*:last-child{border-radius:0 5px 5px 0 !important}.MvPa32ToA6OS8GHeBm5Y>*:last-child{border-right:inherit}`, "",{"version":3,"sources":["webpack://./src/components/wrappers/buttons/ButtonsGroup/styles.module.sass","webpack://./src/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,mBAAA,CCQA,yBDTF,sBAGI,UAAA,CACA,0BACE,UAAA,CACA,WAAA,CAAA,CACN,wBACE,4BAAA,CACA,0BAAA,CACF,oCACE,oCAAA,CACF,mCACE,oCAAA,CACF,mCACE,oBAAA","sourcesContent":["@import \"@/assets/sass/base/breakpoints\"\n\n.group\n  display: inline-flex\n  @include mobile\n    width: 100%\n    svg\n      width: 21px\n      height: 21px\n.group > *\n  border-right: none !important\n  border-radius: 0 !important\n.group > *:first-child\n  border-radius: 5px 0 0 5px !important\n.group > *:last-child\n  border-radius: 0 5px 5px 0 !important\n.group > *:last-child\n  border-right: inherit\n","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": `MvPa32ToA6OS8GHeBm5Y`
};
export default ___CSS_LOADER_EXPORT___;
