// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gFvfDbSVecJenQeehMRA{display:flex;align-items:center;font-family:sans-serif}.UItWl9dJ48r2Wvq6epnk{width:32px;height:32px;display:flex;align-items:center;justify-content:center;border-radius:4px;border:none;background-color:rgba(0,0,0,0);color:#666;margin:0 2px;cursor:pointer;transition:background-color .3s ease}.UItWl9dJ48r2Wvq6epnk:hover{background-color:#f0f0f0}.ZwBQ5CnMXmWKxMJdMjs6{background-color:#000;color:#fff}.ZwBQ5CnMXmWKxMJdMjs6:hover{background-color:#000}.xGD51Tdnd1FPp2rt6HCb{color:#666}.aRdJhFQNNmJx4U2V4JZ0{color:#666;margin:0 4px}`, "",{"version":3,"sources":["webpack://./src/components/pagination/PaginationBar/components/Pagination/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CAEF,sBACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CACA,WAAA,CACA,8BAAA,CACA,UAAA,CACA,YAAA,CACA,cAAA,CACA,oCAAA,CAEA,4BACE,wBAAA,CAEF,sBACE,qBAAA,CACA,UAAA,CAEA,4BACE,qBAAA,CAEJ,sBACE,UAAA,CAEJ,sBACE,UAAA,CACA,YAAA","sourcesContent":[".pagination\n  display: flex\n  align-items: center\n  font-family: sans-serif\n\n.button\n  width: 32px\n  height: 32px\n  display: flex\n  align-items: center\n  justify-content: center\n  border-radius: 4px\n  border: none\n  background-color: transparent\n  color: #666\n  margin: 0 2px\n  cursor: pointer\n  transition: background-color 0.3s ease\n\n  &:hover\n    background-color: #f0f0f0\n\n  &Active\n    background-color: #000\n    color: #fff\n\n    &:hover\n      background-color: #000\n\n  &Next\n    color: #666\n\n.ellipsis\n  color: #666\n  margin: 0 4px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `gFvfDbSVecJenQeehMRA`,
	"button": `UItWl9dJ48r2Wvq6epnk`,
	"buttonActive": `ZwBQ5CnMXmWKxMJdMjs6`,
	"buttonNext": `xGD51Tdnd1FPp2rt6HCb`,
	"ellipsis": `aRdJhFQNNmJx4U2V4JZ0`
};
export default ___CSS_LOADER_EXPORT___;
