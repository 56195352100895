// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Drseqm1BV8i5O8UGnxAX{color:#f13636 !important}.KKAD18ID7Q66NjCaQ7kk{color:#000 !important}`, "",{"version":3,"sources":["webpack://./src/pages/RegisterMultipleWorks/components/QueuedFilesTable/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACF,sBACE,qBAAA","sourcesContent":[".fontColor\n  color: #f13636 !important\n.editButton\n  color: #000 !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fontColor": `Drseqm1BV8i5O8UGnxAX`,
	"editButton": `KKAD18ID7Q66NjCaQ7kk`
};
export default ___CSS_LOADER_EXPORT___;
