// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tya31xAeBAN43FVlMfeD{color:#f13636 !important}.E_ra_7VvCF4RVxsfBAOt{width:120px;height:30px}.k11flG0M_GyAc_CFc6pv{width:120px}.gt8ij_vrT5Eyj0lfbRD3 th{color:#f13636 !important}.MCgSgpcCwuQSvYPjnMvQ{display:flex;gap:10px;align-items:center}`, "",{"version":3,"sources":["webpack://./src/pages/RegisterMultipleWorks/components/RegistrationList/components/NeedValidationTable/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACF,sBACE,WAAA,CACA,WAAA,CACF,sBACE,WAAA,CAEA,yBACE,wBAAA,CACJ,sBACE,YAAA,CACA,QAAA,CACA,kBAAA","sourcesContent":[".fontColor\n  color: #f13636 !important\n.select\n  width: 120px\n  height: 30px\n.input\n  width: 120px\n.tableHeader\n  th\n    color: #f13636 !important\n.title\n  display: flex\n  gap: 10px\n  align-items: center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fontColor": `Tya31xAeBAN43FVlMfeD`,
	"select": `E_ra_7VvCF4RVxsfBAOt`,
	"input": `k11flG0M_GyAc_CFc6pv`,
	"tableHeader": `gt8ij_vrT5Eyj0lfbRD3`,
	"title": `MCgSgpcCwuQSvYPjnMvQ`
};
export default ___CSS_LOADER_EXPORT___;
