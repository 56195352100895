// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bNVjcrkY5P5RnDUIxipH{width:50%}`, "",{"version":3,"sources":["webpack://./src/pages/ListMultipleWorks/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,SAAA","sourcesContent":[".costTable\n  width: 50%\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"costTable": `bNVjcrkY5P5RnDUIxipH`
};
export default ___CSS_LOADER_EXPORT___;
