// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uIVkyFmuMKYOYBGIlrTe,.pxKLqW0kkFpeJEeWErCV{display:flex;gap:10px}.fdgR0epDSaxNQjAyw6vV{width:150px;height:40px}`, "",{"version":3,"sources":["webpack://./src/pages/RegisterMultipleWorks/components/FormRegister/components/VaultInfo/styles.module.sass"],"names":[],"mappings":"AAAA,4CAEE,YAAA,CACA,QAAA,CACF,sBACE,WAAA,CACA,WAAA","sourcesContent":[".vaultInfo,\n.inputs\n  display: flex\n  gap: 10px\n.select\n  width: 150px\n  height: 40px\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vaultInfo": `uIVkyFmuMKYOYBGIlrTe`,
	"inputs": `pxKLqW0kkFpeJEeWErCV`,
	"select": `fdgR0epDSaxNQjAyw6vV`
};
export default ___CSS_LOADER_EXPORT___;
