// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CoZJkLfdJOyr5bQC5nDb{width:150px}.CoZJkLfdJOyr5bQC5nDb.f37easkAzggGkX90OuzV{width:220px}.G1kerme5Wfx2l_rfXRuq{width:100%}.lhpZBHdr2xrcgmczC2d9{display:flex;gap:10px}`, "",{"version":3,"sources":["webpack://./src/pages/ListMultipleWorks/component/FormSection/components/StandardLicense/component/InputTextFields/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,2CACE,WAAA,CACJ,sBACE,UAAA,CACF,sBACE,YAAA,CACA,QAAA","sourcesContent":[".field\n  width: 150px\n  &.big\n    width: 220px\n.input\n  width: 100%\n.row\n  display: flex\n  gap: 10px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `CoZJkLfdJOyr5bQC5nDb`,
	"big": `f37easkAzggGkX90OuzV`,
	"input": `G1kerme5Wfx2l_rfXRuq`,
	"row": `lhpZBHdr2xrcgmczC2d9`
};
export default ___CSS_LOADER_EXPORT___;
