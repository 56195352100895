// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eHja4X1XNvHbIbY77LRB{width:150px;height:40px}.SKYL8hmfzwJlceXG8fz0{width:150px}.SKYL8hmfzwJlceXG8fz0._2WK4a3S_4WdZ4be4zQ4{width:220px}.xqzL9khLqUnRMwNyG36R{width:100%;font-size:13px}.cRRLCX5wxXSEezsb1s8A{display:flex;gap:10px}.AVTt8RDQ5aBA7OjtLslt{display:flex;gap:10px;font-size:13px}.V00MoIFwX907nUO7d8__{font-size:13px;font-weight:bold}.KfSr5zqVzUAppyTQRE6p{width:220px}.oOZivANOYVWS_HdumMEH{font-weight:bold;text-decoration:underline;color:#000;margin-bottom:10px}.ObmZGJrvTqc1axqocqld{display:flex;justify-content:space-between}`, "",{"version":3,"sources":["webpack://./src/pages/RegisterMultipleWorks/components/QueuedFilesTable/components/SeeTermModal/components/EditForm/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,WAAA,CACF,sBACE,WAAA,CACA,2CACE,WAAA,CACJ,sBACE,UAAA,CACA,cAAA,CACF,sBACE,YAAA,CACA,QAAA,CACF,sBACE,YAAA,CACA,QAAA,CACA,cAAA,CACF,sBACE,cAAA,CACA,gBAAA,CACF,sBACE,WAAA,CACF,sBACE,gBAAA,CACA,yBAAA,CACA,UAAA,CACA,kBAAA,CACF,sBACE,YAAA,CACA,6BAAA","sourcesContent":[".select\n  width: 150px\n  height: 40px\n.field\n  width: 150px\n  &.big\n    width: 220px\n.input\n  width: 100%\n  font-size: 13px\n.row\n  display: flex\n  gap: 10px\n.editForm\n  display: flex\n  gap: 10px\n  font-size: 13px\n.selectLabel\n  font-size: 13px\n  font-weight: bold\n.bigInput\n  width: 220px\n.titleInputs\n  font-weight: bold\n  text-decoration: underline\n  color: #000\n  margin-bottom: 10px\n.buttons\n  display: flex\n  justify-content: space-between\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `eHja4X1XNvHbIbY77LRB`,
	"field": `SKYL8hmfzwJlceXG8fz0`,
	"big": `_2WK4a3S_4WdZ4be4zQ4`,
	"input": `xqzL9khLqUnRMwNyG36R`,
	"row": `cRRLCX5wxXSEezsb1s8A`,
	"editForm": `AVTt8RDQ5aBA7OjtLslt`,
	"selectLabel": `V00MoIFwX907nUO7d8__`,
	"bigInput": `KfSr5zqVzUAppyTQRE6p`,
	"titleInputs": `oOZivANOYVWS_HdumMEH`,
	"buttons": `ObmZGJrvTqc1axqocqld`
};
export default ___CSS_LOADER_EXPORT___;
