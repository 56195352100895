// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FnPvgDncjgffc_tagL1W,.pZrKb5J515Q7xZGGR9KQ{display:flex;justify-content:space-between;align-items:center;gap:10px}.m8miuksiLDp9dNi72A3A{font-size:14px;display:flex;align-items:center}.GzRPn2bMgf1nHG3g7kMu{font-weight:bold;color:var(--color-main);margin-right:5px}`, "",{"version":3,"sources":["webpack://./src/pages/ListMultipleWorks/component/QueuedWorksSection/components/Header/components/FiltersBar/styles.module.sass"],"names":[],"mappings":"AAAA,4CAEE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,QAAA,CACF,sBACE,cAAA,CACA,YAAA,CACA,kBAAA,CACF,sBACE,gBAAA,CACA,uBAAA,CACA,gBAAA","sourcesContent":[".filterBar,\n.options\n  display: flex\n  justify-content: space-between\n  align-items: center\n  gap: 10px\n.filterSelected\n  font-size: 14px\n  display: flex\n  align-items: center\n.label\n  font-weight: bold\n  color: var(--color-main)\n  margin-right: 5px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterBar": `FnPvgDncjgffc_tagL1W`,
	"options": `pZrKb5J515Q7xZGGR9KQ`,
	"filterSelected": `m8miuksiLDp9dNi72A3A`,
	"label": `GzRPn2bMgf1nHG3g7kMu`
};
export default ___CSS_LOADER_EXPORT___;
