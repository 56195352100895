import axios from 'axios'

export const setAuthorizationConfig = async (getAccessToken, contentType = 'application/json') => {
  let config = {}

  if (getAccessToken) {
    const accessToken = await getAccessToken()

    config = {
      headers: {
        'content-type': contentType,
        Authorization: `Bearer ${accessToken}`
      }
    }
  }

  return config
}

export const queryApiServices = async ({ config, getAccessToken }) => {
  try {
    let currentConfig = {}

    if (getAccessToken) {
      const accessToken = await getAccessToken()
      currentConfig = {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      }
    }
    const response = await axios({ ...config, ...currentConfig })
    const { data } = response

    return {
      data,
      error: null
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error

      const { response } = axiosError

      let message = 'http request failed'

      if (response && response.statusText) {
        message = response.statusText
      }

      if (axiosError.message) {
        message = axiosError.message
      }

      if (response && response.data && response.data.message) {
        message = response.data.message
      }

      return {
        data: null,
        error: {
          message
        }
      }
    }

    return {
      data: null,
      error: {
        message: error.message
      }
    }
  }
}
