// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TqCvEmAGlOeCc74_H_Mi{display:flex;justify-content:space-between;align-items:center}`, "",{"version":3,"sources":["webpack://./src/pages/ListMultipleWorks/component/QueuedWorksSection/components/Header/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA","sourcesContent":[".header\n  display: flex\n  justify-content: space-between\n  align-items: center"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `TqCvEmAGlOeCc74_H_Mi`
};
export default ___CSS_LOADER_EXPORT___;
