// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RpZDo5fPFbYUArM4xIJC{display:flex;justify-content:space-between;align-items:center;padding:10px 24px}.yKB1iI8Y5n5S7oCH1RFo{height:25px !important;width:110px !important;border-radius:0 !important}.I7zq2JBp_quarxi3LsL5{font-size:12px;text-align:right;font-weight:bold;color:#000}`, "",{"version":3,"sources":["webpack://./src/pages/ListMultipleWorks/component/AddWorksSection/components/WorksList/components/WorkCard/component/Footer/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,iBAAA,CACF,sBACE,sBAAA,CACA,sBAAA,CACA,0BAAA,CACF,sBACE,cAAA,CACA,gBAAA,CACA,gBAAA,CACA,UAAA","sourcesContent":[".footer\n  display: flex\n  justify-content: space-between\n  align-items: center\n  padding: 10px 24px\n.button\n  height: 25px !important\n  width: 110px !important\n  border-radius: 0 !important\n.info\n  font-size: 12px\n  text-align: right\n  font-weight: bold\n  color: #000"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `RpZDo5fPFbYUArM4xIJC`,
	"button": `yKB1iI8Y5n5S7oCH1RFo`,
	"info": `I7zq2JBp_quarxi3LsL5`
};
export default ___CSS_LOADER_EXPORT___;
