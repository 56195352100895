// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xpSR5pVsBdzaE6WONiwI{align-items:center;display:flex;gap:10px}.XOuCIg_j6WgPBPcb4UP5{color:red !important;font-size:20px}`, "",{"version":3,"sources":["webpack://./src/pages/ListMultipleWorks/component/QueuedWorksSection/components/QueuedWorksTable/components/Buttons/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,YAAA,CACA,QAAA,CACF,sBACE,oBAAA,CACA,cAAA","sourcesContent":[".buttons\n  align-items: center\n  display: flex\n  gap: 10px\n.delete\n  color: red !important\n  font-size: 20px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": `xpSR5pVsBdzaE6WONiwI`,
	"delete": `XOuCIg_j6WgPBPcb4UP5`
};
export default ___CSS_LOADER_EXPORT___;
