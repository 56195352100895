// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PB7ja6pDDBb9N3hlcb2e{display:flex;column-gap:20px}.K_afrLM3vxVFvvuhr4KK{width:60%}._xPOzBBcV4QXzGI3hYWO{width:40%}._xPOzBBcV4QXzGI3hYWO textarea{width:100%;height:220px}.sqEjmhioPsxXT5HWfwaX{display:flex;flex-wrap:wrap;justify-content:space-between;column-gap:10px}.JSokVJnfbPXfZwOkZAXJ{flex:1;min-width:100px;display:flex;flex-direction:column}.GlUbyn4zhqZaTUurrcQh{width:100%}.Bh5nRgEPrZGNGaRYdVF7{flex-grow:0;flex-shrink:0;flex-basis:200px}.NSljScK6RWUGyp5Z6DXP{font-weight:bold}.y61HtZ6V33gOYgEYBj5f{font-size:20px}.d63wPdzDnyAQcyABKb5A{padding-left:15px}`, "",{"version":3,"sources":["webpack://./src/pages/User/pages/Account/components/AppLicenses/components/LicensesForm/components/VaultSetupForm/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA,CACF,sBACE,SAAA,CACF,sBACE,SAAA,CACA,+BACE,UAAA,CACA,YAAA,CACJ,sBACE,YAAA,CACA,cAAA,CACA,6BAAA,CACA,eAAA,CACF,sBACE,MAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CACF,sBACE,UAAA,CACF,sBACE,WAAA,CACA,aAAA,CACA,gBAAA,CACF,sBACE,gBAAA,CACF,sBACE,cAAA,CACF,sBACE,iBAAA","sourcesContent":[".formColumns\n  display: flex\n  column-gap: 20px\n.mainColumn\n  width: 60%\n.otherColumn\n  width: 40%\n  textarea\n    width: 100%\n    height: 220px\n.flexRow\n  display: flex\n  flex-wrap: wrap\n  justify-content: space-between\n  column-gap: 10px\n.inputField\n  flex: 1\n  min-width: 100px\n  display: flex\n  flex-direction: column\n.input\n  width: 100%\n.inputPath\n  flex-grow: 0\n  flex-shrink: 0\n  flex-basis: 200px\n.mainCheckbox\n  font-weight: bold\n.title\n  font-size: 20px\n.content\n  padding-left: 15px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formColumns": `PB7ja6pDDBb9N3hlcb2e`,
	"mainColumn": `K_afrLM3vxVFvvuhr4KK`,
	"otherColumn": `_xPOzBBcV4QXzGI3hYWO`,
	"flexRow": `sqEjmhioPsxXT5HWfwaX`,
	"inputField": `JSokVJnfbPXfZwOkZAXJ`,
	"input": `GlUbyn4zhqZaTUurrcQh`,
	"inputPath": `Bh5nRgEPrZGNGaRYdVF7`,
	"mainCheckbox": `NSljScK6RWUGyp5Z6DXP`,
	"title": `y61HtZ6V33gOYgEYBj5f`,
	"content": `d63wPdzDnyAQcyABKb5A`
};
export default ___CSS_LOADER_EXPORT___;
