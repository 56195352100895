// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Lt_xPfnmTxuM7Il4WpbD{font-weight:bold;color:var(--color-main);margin-bottom:10px}.dSZHu_7pNQRB2selD1ZB{width:20px;height:20px;margin-right:10px}`, "",{"version":3,"sources":["webpack://./src/pages/ListMultipleWorks/component/AddWorksSection/components/WorksList/components/Header/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,uBAAA,CACA,kBAAA,CACF,sBACE,UAAA,CACA,WAAA,CACA,iBAAA","sourcesContent":[".textBig\n  font-weight: bold\n  color: var(--color-main)\n  margin-bottom: 10px\n.checkbox\n  width: 20px\n  height: 20px\n  margin-right: 10px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textBig": `Lt_xPfnmTxuM7Il4WpbD`,
	"checkbox": `dSZHu_7pNQRB2selD1ZB`
};
export default ___CSS_LOADER_EXPORT___;
