// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BVl6tLnyQKGdUfoONSiB{display:flex;font-size:14px;gap:15px}`, "",{"version":3,"sources":["webpack://./src/pages/RegisterMultipleWorks/components/FormRegister/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,cAAA,CACA,QAAA","sourcesContent":[".formRegister\n  display: flex\n  font-size: 14px\n  gap: 15px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formRegister": `BVl6tLnyQKGdUfoONSiB`
};
export default ___CSS_LOADER_EXPORT___;
