// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PUCnGaNFUMOUPX9cbDzs{width:25px;height:25px;background:#0c8c1a;display:flex;border-radius:100%;align-items:center;justify-content:center;color:#fff;font-size:15px}`, "",{"version":3,"sources":["webpack://./src/components/templates/RegularTemplate/components/ProgressWidget/components/Icon/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,cAAA","sourcesContent":[".successContainer\n  width: 25px\n  height: 25px\n  background: #0c8c1a\n  display: flex\n  border-radius: 100%\n  align-items: center\n  justify-content: center\n  color: #fff\n  font-size: 15px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successContainer": `PUCnGaNFUMOUPX9cbDzs`
};
export default ___CSS_LOADER_EXPORT___;
