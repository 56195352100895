// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._LbU0XdxDNlI5pPTUs18{display:flex;flex-wrap:wrap;gap:15px 15px;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/pages/ListMultipleWorks/component/AddWorksSection/components/WorksList/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,cAAA,CACA,aAAA,CACA,sBAAA","sourcesContent":[".cardsContainer\n  display:  flex\n  flex-wrap: wrap\n  gap:15px 15px\n  justify-content: center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardsContainer": `_LbU0XdxDNlI5pPTUs18`
};
export default ___CSS_LOADER_EXPORT___;
