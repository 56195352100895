// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WOL1DrmpFVg_pcJZ8rxs{color:#282828}.Iicj0ku0fegAxlHBcsbS{display:flex;justify-content:space-between}`, "",{"version":3,"sources":["webpack://./src/pages/RegisterMultipleWorks/components/QueuedFilesTable/components/SeeTermModal/components/TermItem/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACF,sBACE,YAAA,CACA,6BAAA","sourcesContent":[".icon\n  color: #282828\n.container\n  display: flex\n  justify-content: space-between\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `WOL1DrmpFVg_pcJZ8rxs`,
	"container": `Iicj0ku0fegAxlHBcsbS`
};
export default ___CSS_LOADER_EXPORT___;
