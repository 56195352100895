// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.L1vtlcbx8S2koisy1ezb,.jSE66RUgiXebbunrYMZ1{display:flex;align-items:center;gap:10px}.wKeVMFL9La3k14aU32mT{font-size:14px;display:flex;align-items:center}.a1CUn3MSfLsVhJZ2T11n{font-weight:bold;color:var(--color-main);margin-right:5px}`, "",{"version":3,"sources":["webpack://./src/pages/ListMultipleWorks/component/AddWorksSection/components/Header/components/FiltersBar/styles.module.sass"],"names":[],"mappings":"AAAA,4CAEE,YAAA,CACA,kBAAA,CACA,QAAA,CACF,sBACE,cAAA,CACA,YAAA,CACA,kBAAA,CACF,sBACE,gBAAA,CACA,uBAAA,CACA,gBAAA","sourcesContent":[".filterBar,\n.options\n  display: flex\n  align-items: center\n  gap: 10px\n.filterSelected\n  font-size: 14px\n  display: flex\n  align-items: center\n.label\n  font-weight: bold\n  color: var(--color-main)\n  margin-right: 5px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterBar": `L1vtlcbx8S2koisy1ezb`,
	"options": `jSE66RUgiXebbunrYMZ1`,
	"filterSelected": `wKeVMFL9La3k14aU32mT`,
	"label": `a1CUn3MSfLsVhJZ2T11n`
};
export default ___CSS_LOADER_EXPORT___;
