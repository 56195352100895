// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XE2e35fBhQovMXRW0sZc{display:flex;justify-content:space-between}.owL4NsfgPonOwdAw6PKE{padding-right:5px}.iSHFo27N47DOwA_q2BQ6{cursor:pointer;background:rgba(0,0,0,0);border:none;line-height:1;width:12px;height:12px;padding:0;flex-shrink:0;flex-flow:0}`, "",{"version":3,"sources":["webpack://./src/components/templates/RegularTemplate/components/ProgressWidget/components/Header/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACF,sBACE,iBAAA,CACF,sBACE,cAAA,CACA,wBAAA,CACA,WAAA,CACA,aAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CACA,aAAA,CACA,WAAA","sourcesContent":[".container\n  display: flex\n  justify-content: space-between\n.title\n  padding-right: 5px\n.closeButton\n  cursor: pointer\n  background: transparent\n  border: none\n  line-height: 1\n  width: 12px\n  height: 12px\n  padding: 0\n  flex-shrink: 0\n  flex-flow: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `XE2e35fBhQovMXRW0sZc`,
	"title": `owL4NsfgPonOwdAw6PKE`,
	"closeButton": `iSHFo27N47DOwA_q2BQ6`
};
export default ___CSS_LOADER_EXPORT___;
