// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pZyCfJh3LnAV1sQXEumM{display:flex;font-size:14px;gap:10px}.ALpdHGn2NZxffx55oYfS{width:150px;height:40px}`, "",{"version":3,"sources":["webpack://./src/pages/ListMultipleWorks/component/FormSection/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,cAAA,CACA,QAAA,CACF,sBACE,WAAA,CACA,WAAA","sourcesContent":[".formSection\n  display: flex\n  font-size: 14px\n  gap: 10px\n.select\n  width: 150px\n  height: 40px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formSection": `pZyCfJh3LnAV1sQXEumM`,
	"select": `ALpdHGn2NZxffx55oYfS`
};
export default ___CSS_LOADER_EXPORT___;
