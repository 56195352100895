// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KGWaKgyQGCcIkLQba8yx{display:flex;margin-bottom:5px;align-items:center}.WhmYkjgNIIgDo5NTsn9k{flex-grow:1}.TfoYZKteBSa_BKydM7K8{flex-grow:0;flex-shrink:0}`, "",{"version":3,"sources":["webpack://./src/components/templates/RegularTemplate/components/ProgressWidget/components/BodyContainer/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,iBAAA,CACA,kBAAA,CACF,sBACE,WAAA,CACF,sBACE,WAAA,CACA,aAAA","sourcesContent":[".bodyContainer\n  display: flex\n  margin-bottom: 5px\n  align-items: center\n.info\n  flex-grow: 1\n.icon\n  flex-grow: 0\n  flex-shrink: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bodyContainer": `KGWaKgyQGCcIkLQba8yx`,
	"info": `WhmYkjgNIIgDo5NTsn9k`,
	"icon": `TfoYZKteBSa_BKydM7K8`
};
export default ___CSS_LOADER_EXPORT___;
