// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BvR8JabR4lrfiKdWYJoI{display:flex}.DwTp1kZBIPHgE7faJ5Rb{margin-right:20px;width:150px;height:200px;object-fit:cover}`, "",{"version":3,"sources":["webpack://./src/pages/Authors/pages/AuthorsAssets/components/AssetsList/AssetCard/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACF,sBACE,iBAAA,CACA,WAAA,CACA,YAAA,CACA,gBAAA","sourcesContent":[".assetCard\n  display: flex\n.image\n  margin-right: 20px\n  width: 150px\n  height: 200px\n  object-fit: cover"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assetCard": `BvR8JabR4lrfiKdWYJoI`,
	"image": `DwTp1kZBIPHgE7faJ5Rb`
};
export default ___CSS_LOADER_EXPORT___;
