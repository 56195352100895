// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ze4L4wQMLhtw0TeOskWI{width:100%;background-color:#e6e6e6;overflow:hidden;border-radius:30px;height:10px;border:0}.mdGFHSRAFn9iuEg1s5aZ{display:block;background-color:#659cef;transition:width 500ms ease-in-out}`, "",{"version":3,"sources":["webpack://./src/components/templates/RegularTemplate/components/ProgressWidget/components/ProgressBar/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,wBAAA,CACA,eAAA,CACA,kBAAA,CACA,WAAA,CACA,QAAA,CACF,sBACE,aAAA,CACA,wBAAA,CACA,kCAAA","sourcesContent":[".progressBar\n  width: 100%\n  background-color: #e6e6e6\n  overflow: hidden\n  border-radius: 30px\n  height: 10px\n  border: 0\n.progressBarFill\n  display: block\n  background-color: #659cef\n  transition: width 500ms ease-in-out"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBar": `Ze4L4wQMLhtw0TeOskWI`,
	"progressBarFill": `mdGFHSRAFn9iuEg1s5aZ`
};
export default ___CSS_LOADER_EXPORT___;
