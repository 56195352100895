// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JiHlKU6FBLJo6RVwydX3{display:flex;align-items:center;color:#000 !important}.O8HeDyoE18uUz6rDHwyn,.DFXtHjaCnDEVem9qzeon{color:#000 !important}.DFXtHjaCnDEVem9qzeon{font-weight:bold}`, "",{"version":3,"sources":["webpack://./src/pages/RegisterMultipleWorks/components/RegistrationList/components/FileInfo/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,qBAAA,CACF,4CAEE,qBAAA,CACF,sBACE,gBAAA","sourcesContent":[".file\n  display: flex\n  align-items: center\n  color: #000 !important\n.removeFile,\n.quantity\n  color: #000 !important\n.quantity\n  font-weight: bold\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"file": `JiHlKU6FBLJo6RVwydX3`,
	"removeFile": `O8HeDyoE18uUz6rDHwyn`,
	"quantity": `DFXtHjaCnDEVem9qzeon`
};
export default ___CSS_LOADER_EXPORT___;
