// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lygLa_C40aRb4SascKlD{width:100%;display:flex;margin-bottom:5px;align-items:center}.pa8T8cXWiVOIPQH8VY4F{font-size:13px;position:relative}input[type=checkbox]:disabled+span{-moz-opacity:.5;-webkit-opacity:.5;opacity:.5;color:#000 !important}.KAvO26PvPNsrqrXSn5bc{color:#a5a5a5 !important;cursor:not-allowed}.KAvO26PvPNsrqrXSn5bc input[type=checkbox]{cursor:not-allowed}.VMMOIrR1PLlhA_8906qH{background:rgba(0,0,0,.3);position:absolute;top:0;left:0;width:100%;height:100%;display:flex;justify-content:center;align-items:center}`, "",{"version":3,"sources":["webpack://./src/pages/RegisterMultipleWorks/components/FormRegister/components/WorksOptions/components/MaskingSettings/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,iBAAA,CACA,kBAAA,CACF,sBACE,cAAA,CACA,iBAAA,CACF,mCACE,eAAA,CACA,kBAAA,CACA,UAAA,CACA,qBAAA,CACF,sBACE,wBAAA,CACA,kBAAA,CACA,2CACE,kBAAA,CACJ,sBACE,yBAAA,CACA,iBAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".input\n  width: 100%\n  display: flex\n  margin-bottom: 5px\n  align-items: center\n.form\n  font-size: 13px\n  position: relative\ninput[type=\"checkbox\"]:disabled+span\n  -moz-opacity: .5\n  -webkit-opacity: .5\n  opacity: .5\n  color: black !important\n.disabled\n  color: #a5a5a5 !important\n  cursor: not-allowed\n  input[type=\"checkbox\"]\n    cursor: not-allowed\n.lock\n  background: rgba(0, 0, 0, 0.3)\n  position: absolute\n  top: 0\n  left: 0\n  width: 100%\n  height: 100%\n  display: flex\n  justify-content: center\n  align-items: center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `lygLa_C40aRb4SascKlD`,
	"form": `pa8T8cXWiVOIPQH8VY4F`,
	"disabled": `KAvO26PvPNsrqrXSn5bc`,
	"lock": `VMMOIrR1PLlhA_8906qH`
};
export default ___CSS_LOADER_EXPORT___;
