// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Kfo1Rq3h2_wSX4K_2b1h,.hzdJyGfd97ajaDsEcDf1{display:flex;gap:10px}.b3p43EztojCw0UxE3YDn{width:150px;height:40px}.frqMXZfvyz9L05mejZdw{display:flex;gap:10px;margin-bottom:10px}`, "",{"version":3,"sources":["webpack://./src/pages/RegisterMultipleWorks/components/FormRegister/components/WorksOptions/styles.module.sass"],"names":[],"mappings":"AAAA,4CAEE,YAAA,CACA,QAAA,CACF,sBACE,WAAA,CACA,WAAA,CACF,sBACE,YAAA,CACA,QAAA,CACA,kBAAA","sourcesContent":[".vaultInfo,\n.inputs\n  display: flex\n  gap: 10px\n.select\n  width: 150px\n  height: 40px\n.options\n  display: flex\n  gap: 10px\n  margin-bottom: 10px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vaultInfo": `Kfo1Rq3h2_wSX4K_2b1h`,
	"inputs": `hzdJyGfd97ajaDsEcDf1`,
	"select": `b3p43EztojCw0UxE3YDn`,
	"options": `frqMXZfvyz9L05mejZdw`
};
export default ___CSS_LOADER_EXPORT___;
