// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kZKPAwfpbz5LzFmG6fUG,.dbKQvFfwcN833101Owfv{display:flex;align-items:center}.kZKPAwfpbz5LzFmG6fUG{justify-content:space-between}.dbKQvFfwcN833101Owfv,.PiskI2HRpBJK2aig2dQR{gap:10px}.PiskI2HRpBJK2aig2dQR{display:flex}`, "",{"version":3,"sources":["webpack://./src/pages/RegisterMultipleWorks/components/RegistrationList/components/Header/styles.module.sass"],"names":[],"mappings":"AAAA,4CAEE,YAAA,CACA,kBAAA,CACF,sBACE,6BAAA,CACF,4CAEE,QAAA,CACF,sBACE,YAAA","sourcesContent":[".header,\n.options\n  display: flex\n  align-items: center\n.header\n  justify-content: space-between\n.options,\n.buttons\n  gap: 10px\n.buttons\n  display: flex\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `kZKPAwfpbz5LzFmG6fUG`,
	"options": `dbKQvFfwcN833101Owfv`,
	"buttons": `PiskI2HRpBJK2aig2dQR`
};
export default ___CSS_LOADER_EXPORT___;
