// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kzlYK0ETLfMrUfQHuWDs{padding:5px;width:200px;max-height:100px;font-size:11px;background:#f1f2f3;border-radius:10px;position:fixed;right:20px;z-index:1000;top:70px}.kzlYK0ETLfMrUfQHuWDs h1,.kzlYK0ETLfMrUfQHuWDs h2,.kzlYK0ETLfMrUfQHuWDs h3,.kzlYK0ETLfMrUfQHuWDs h4,.kzlYK0ETLfMrUfQHuWDs h5,.kzlYK0ETLfMrUfQHuWDs h6{font-size:11px}`, "",{"version":3,"sources":["webpack://./src/components/templates/RegularTemplate/components/ProgressWidget/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,WAAA,CACA,gBAAA,CACA,cAAA,CACA,kBAAA,CACA,kBAAA,CACA,cAAA,CACA,UAAA,CACA,YAAA,CACA,QAAA,CAEA,sJACE,cAAA","sourcesContent":[".widget\n  padding: 5px\n  width: 200px\n  max-height: 100px\n  font-size: 11px\n  background: #f1f2f3\n  border-radius: 10px\n  position: fixed\n  right: 20px\n  z-index: 1000\n  top: 70px\n.widget\n  h1, h2, h3, h4, h5, h6\n    font-size: 11px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widget": `kzlYK0ETLfMrUfQHuWDs`
};
export default ___CSS_LOADER_EXPORT___;
