// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.O4Hs9cnnI5RRaiOQyA0z{align-items:center;display:flex;margin-top:15px;justify-content:space-between}.J70teFAHroaPUwhaOFoi{font-size:14px}`, "",{"version":3,"sources":["webpack://./src/components/pagination/PaginationBar/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,YAAA,CACA,eAAA,CACA,6BAAA,CACF,sBACE,cAAA","sourcesContent":[".paginationBar\n  align-items: center\n  display: flex\n  margin-top: 15px\n  justify-content: space-between\n.totalPage\n  font-size: 14px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationBar": `O4Hs9cnnI5RRaiOQyA0z`,
	"totalPage": `J70teFAHroaPUwhaOFoi`
};
export default ___CSS_LOADER_EXPORT___;
